<template>
  <div class="dropdown-wrapper">
    <div class="field-label">{{title}}</div>
    <div class="current-value-field" @click="$emit('openDropdown')">
      {{currentValue}}
      <div
        class="open-dropdown-btn"
        :class="{'close-dropdown-btn': isDropdownOpened}"
      >
      </div>
    </div>
    <ActionMenu
      :width="'360px'"
      :height="menuHeight"
      :top="'72px'"
      :isActionMenuShown="isDropdownOpened"
      @closeActionMenu="$emit('closeDropdown')"
    >
      <ActionMenuItem
        v-for="(value, name, index) in optionsObj"
        :key="index"
        :text="name"
        @actionMenuItemClick="$emit('setNewValue', name)"
      />
    </ActionMenu>
  </div>
</template>

<script>
import ActionMenu from './actionMenu/ActionMenu.vue'
import ActionMenuItem from './actionMenu/ActionMenuItem.vue'

export default {
  props: [
    'title',
    'currentValue',
    'isDropdownOpened',
    'optionsObj',
    'menuHeight',
  ],
  components: {
    ActionMenu,
    ActionMenuItem,
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.dropdown-wrapper {
  width: 100%;
  height: 68px;
  position: relative;

  .field-label {
    color: $fontColorBlack;
    font-size: $fontSizeMinified;
  }

  .current-value-field {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 6px;
    position: relative;
    font-size: $fontSizeStandart;
    border: 1px solid $grayBorderColor;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    .open-dropdown-btn {
      width: 20px;
      height: 20px;
      top: 12px;
      right: 19px;
      position: absolute;
      background-position: center;
      background-size: 20px;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/chevron-down-black-icon.svg');
    }

    .close-dropdown-btn {
      background-image: url('../../assets/icons/chevron-up-black-icon.svg');
    }
  }
}
</style>