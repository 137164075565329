<template>
  <div :class="{ 'content-width': fitContent }" class="pipeline-goal-field">
    <div
      v-if="currentMode === modes.VIEW"
      class="value-container"
      :title="editableValue"
      @click="setEditMode"
    >
      <span class="goal-title">{{ placeholder }}:</span>
      <b> {{ containerValue }}</b>
    </div>
    <input
      v-if="currentMode === modes.EDIT"
      class="input-field"
      :class="{ 'error-state': errorState }"
      type="text"
      ref="inputField"
      :placeholder="placeholder"
      v-model="editableValue"
      @focusout="handleInputBlur"
    />
  </div>
</template>

<script>
export default {
  props: [
    'placeholder',
    'value',
    'isNotEditable',
    'isSubscriptionActive',
    // Disables fixed width to the container and takes width equal to content. BYB-466
    'fitContent',
  ],
  data() {
    return {
      modes: {
        VIEW: 'VIEW',
        EDIT: 'EDIT',
      },
      currentMode: '',
      editableValue: '',
      errorState: false,
    }
  },
  created() {
    this.currentMode =
      !this.value && !this.isNotEditable ? this.modes.EDIT : this.modes.VIEW
    this.editableValue = this.value || ''
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue) {
        this.editableValue = newValue
        this.currentMode = this.modes.VIEW
      }
    },
  },
  computed: {
    containerValue() {
      return this.placeholder === 'Monthly Revenue Goal' &&
        this.editableValue.toString().length > 12
        ? '$' +
            this.editableValue
              .substring(0, 12)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            '...'
        : this.placeholder === 'Monthly Revenue Goal'
        ? '$' +
          this.editableValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.placeholder === 'Confidence Level %'
        ? this.editableValue + '%'
        : !this.isNotEditable && this.editableValue.length > 16
        ? this.editableValue.substring(0, 16) + '...'
        : this.isNotEditable && this.editableValue.length > 36
        ? this.editableValue.substring(0, 36) + '...'
        : this.editableValue
    },
  },
  methods: {
    setEditMode() {
      if (!this.isSubscriptionActive) return
      this.currentMode = this.modes.EDIT
    },

    handleInputBlur() {
      if (!this.isSubscriptionActive) {
        this.editableValue = this.value || ''
        return
      }

      this.errorState = false
      this.editableValue = this.editableValue.trim()

      if (
        this.placeholder === 'Monthly Revenue Goal' &&
        !this.editableValue.match(/^[0-9]*$/)
      ) {
        this.errorState = true
        return
      }

      if (
        (this.placeholder === 'Confidence Level %' &&
          !this.editableValue.match(/^[0-9]*$/)) ||
        (this.placeholder === 'Confidence Level %' &&
          this.editableValue.match(/^[0-9]*$/) &&
          (+this.editableValue < 0 || +this.editableValue > 100))
      ) {
        this.errorState = true
        return
      }

      if (this.editableValue) {
        this.currentMode = this.modes.VIEW
      }

      if (this.editableValue !== this.value) {
        this.$emit('pipelineGoalUpdated', this.editableValue)
        this.errorState = false
        return
      }
      if (this.editableValue && this.editableValue === this.value) {
        this.currentMode = this.modes.VIEW
        this.errorState = false
        return
      }
      this.editableValue = this.value
      this.currentMode = this.modes.EDIT
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.pipeline-goal-field {
  height: 46px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  outline: none;
  overflow: hidden;

  .value-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6px;
    height: 100%;
    max-width: 350px;
    padding: 0 8px;
    font-size: $fontSizeStandart;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .input-field {
    width: 150px;
    height: 46px;
    padding: 3px;
    font-size: $fontSizeStandart;
    box-sizing: border-box;
    border: 1px solid $grayBorderColor;
    border-radius: 4px;
    outline: none;
    &::placeholder {
      font-size: $fontSizeMinified;
    }
  }

  .error-state {
    border: 1px solid $redWarningColor;
    border-radius: 4px;
  }
}

.content-width {
  width: fit-content;
  max-width: 410px;
}
</style>
